/*!
 * investors-overview.js
 * Created by Santiago Prada
 * Copyright © 2016 IBM. All rights reserved.
 */


//Global Variables
/*
var applicationProperties = {
    mainCSSId: "main-css",
};
*/

$(function() {

  //Initiate components
  // var stock = new Stock($("#stock"), applicationProperties);

  //Delay action by a few miliseconds to guarrantee the element in present in the dom
  setTimeout(function(){
  // Add style to active links, desktop and mobile
  $("#investors-link").addClass("primary-page-active");
  $("#investors-mobile-link").addClass("mobile-primary-page-active");
  $("#investors-overview-mobile-link").addClass("mobile-secondary-page-active");

},100);

  //expandable sections Logic
  var sections = [
    { "button" : $("#view-calendar-button"), "section" : $("#view-calendar-section")},
    { "button" : $("#view-reports-button"), "section" : $("#view-reports-section")},
    { "button" : $("#view-faq-button"), "section" : $("#view-faq-section")},
    { "button" : $("#view-contact-button"), "section" : $("#view-contact-section") }
  ];

  var expandable = new ExpandableSections(sections);

});
